import React from "react";

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useOktaAuth } from '@okta/okta-react';

//import Button from '@mui/material/Button';
/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */

function SimpleDialog(props) {
  const { onClose, open, onLogin } = props;

  const handleClose = () => {
    onClose();
  };

  const handleLogin = () => {
    onLogin();
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Sign In</DialogTitle>
      <Button variant="outline" onClick={() => { handleLogin(); }}> <img
        alt=""
        src="okta.svg"
        width="30"
        height="30"
        className="d-inline-block align-top"
      />{'  '} Continue with Okta Account</Button>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};



export const SignInButton = () => {
  const [open, setOpen] = React.useState(false);
  const { authState, oktaAuth } = useOktaAuth();

  const login = async () => oktaAuth.signInWithRedirect();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };




  return (
    <div>
      <Button variant="primary" onClick={handleClickOpen}>
        Sign In
      </Button>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        onLogin={login}
      />
    </div>
  );
}
