import React from "react";


import Button from '@mui/material/Button';
import { useOktaAuth } from '@okta/okta-react';
/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { authState, oktaAuth } = useOktaAuth();

    const handleLogout = async () => oktaAuth.signOut();
    return (<Button onClick={() => handleLogout()} variant="contained">Sign Out</Button>)
}
