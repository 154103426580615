import React from "react";

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const ProfileData = (props) => {
    return (
        <div id="profile-div">
            <p><strong>Name: </strong> {props.graphData.name}</p>
            <p><strong>User Name: </strong> {props.graphData.username}</p>
            <p><strong>Email: </strong> {props.graphData.email}</p>
            <p><strong>Domain: </strong> {props.graphData.iss}</p>
            <p><strong>Client ID: </strong> {props.graphData.aud}</p>
            <p><strong>User ID: </strong> {props.graphData.sub}</p>
        </div>
    );
};
