// src/Login.js
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

const Login = () => {
  const { oktaAuth } = useOktaAuth();

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  return <button onClick={login}>Login with Okta</button>;
};

export default Login;
