import React, { useEffect, useState } from 'react';

import './App.css';
import { useOktaAuth } from '@okta/okta-react';
import { PageLayout } from './components/PageLayout';
import { ProfileData } from './components/ProfileData';
import Button from '@mui/material/Button';

const ProfileContent = (props) => {
 
  const {graphData } = props;
  //if(props.apiResponse) setGraphData({userPrincipalName:props.apiResponse});


  function RequestProfileData() {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      props.callApi();
  }

  return (
      <>
          <h5 className="profileContent">Welcome</h5>
          {graphData ? (
              <ProfileData graphData={graphData} />
          ) : (
              <Button variant="secondary" onClick={RequestProfileData}>
                  Request Profile
              </Button>
          )}
      </>
  );
};

const MainContent = (props) => {
  const { oktaAuth, authState } = useOktaAuth();
  return (
      <div className="App">
         {(authState && authState?.isAuthenticated) ? <ProfileContent {...props}/> : <h5 className="card-title">Please sign-in to see your profile information.</h5>}
      </div>
  );
};

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [apiResponse, setApiResponse] = useState('');
  const [graphData, setGraphData] = useState(null);
  const callApi = async () => {
    if (authState.isAuthenticated) {
      const accessToken = await oktaAuth.getAccessToken();
      console.log('accessToken',accessToken);
      console.log('authState',authState);

      const response = await fetch('https://testapp120241106100346.azurewebsites.net/api/values', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify( authState )
      });
      const data = await response.json();
      setApiResponse(data.name);
      setGraphData(data);
    }
  };
  if (!authState) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <PageLayout>
        <MainContent callApi={callApi} apiResponse={apiResponse} graphData={graphData}/>
    </PageLayout>
);
};

export default Home;
